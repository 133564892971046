import { createAction, nanoid } from "@reduxjs/toolkit";
import { Marker, Polyline } from "../mainMiddleware";

export const initMapAction = createAction(
  "initMapAction",
  function prepare(element: HTMLDivElement) {
    return {
      payload: {
        element,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const resetMap = createAction("resetMap", function prepare() {
  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
    },
  };
});

export const setMarkersAction = createAction(
  "setMarkersAction",
  function prepare(markers: { [id: number]: Marker }) {
    return {
      payload: {
        markers,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPolylinesAction = createAction(
  "setPolylinesAction",
  function prepare(polylines: { [id: number]: Polyline }) {
    return {
      payload: {
        polylines,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const nodeMouseClickAction = createAction(
  "nodeMouseClickAction",
  function prepare(marker: Marker) {
    return {
      payload: {
        marker,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const nodeMouseOverAction = createAction(
  "nodeMouseOverAction",
  function prepare(marker: Marker) {
    return {
      payload: {
        marker,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const nodeMouseOutAction = createAction(
  "nodeMouseOutAction",
  function prepare(marker: Marker) {
    return {
      payload: {
        marker,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
