import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../store";

const selectMap = (s: AppState) => s.mapState.map;
export const mapSelector = createSelector([selectMap], (m) => m);

const selectMarkers = (s: AppState) => s.mapState.markers;
export const markersSelectors = createSelector([selectMarkers], (m) => m);

const selectPolylines = (s: AppState) => s.mapState.polylines;
export const polylinesSelector = createSelector([selectPolylines], (m) => m);
