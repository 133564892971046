import { createReducer } from "@reduxjs/toolkit";
import { mapOptions } from "../../config/mapOptions";
import {
  initMapAction,
  resetMap,
  setMarkersAction,
  setPolylinesAction,
} from "./mapActions";
import { MapState } from "./mapState";
import usStates from "./usStates.json";

const center = { lat: 39.8097343, lng: -98.5556199 };
const zoom = 5;

function initState(): MapState {
  return {
    map: undefined,
    drawingManager: undefined,
    polylines: {},
    markers: {},
  };
}

export const mapReducer = createReducer(initState(), (builder) => {
  builder.addCase(resetMap, (state, action) => {
    return state;
  });
  builder.addCase(initMapAction, (state, action) => {
    if (state.map) return state;
    const m = new window.google.maps.Map(action.payload.element, {
      center,
      zoom,
    });
    m.data.addGeoJson(usStates);
    m.data.setStyle({
      fillColor: "transparent",
      strokeWeight: 2,
      strokeColor: "#9e9e9e",
    });
    m.setOptions(mapOptions);
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.POLYLINE,
          google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
    });
    drawingManager.setMap(m);
    state.drawingManager = drawingManager;
    state.map = m;
    return state;
  });

  builder.addCase(setMarkersAction, (state, action) => {
    state.markers = action.payload.markers;
    return state;
  });
  builder.addCase(setPolylinesAction, (state, action) => {
    state.polylines = action.payload.polylines;
    return state;
  });
});
