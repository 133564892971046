import { NodeType } from "../../entities/nodeType";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

export const nodeTypesSelector = (state: AppState): Array<NodeType> =>
  mapToArray(state.nodeTypeState.nodeTypes);

export const nodeTypeSelector = (
  state: AppState,
  id: number | undefined
): NodeType | undefined => {
  if (id === undefined) return undefined;
  return state.nodeTypeState.nodeTypes[id];
};
