import { createAction, nanoid } from "@reduxjs/toolkit";

export const setDrawerOpenAction = createAction(
  "setDrawerOpenAction",
  function prepare(open: boolean) {
    return {
      payload: {
        open,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
