import DropYardIcon from "./assets/drop_yard.png";
import RailyardIcon from "./assets/railyard.png";
import WarehouseIcon from "./assets/warehouse.png";
import WorkshopIcon from "./assets/workshop.png";
import UnkownIcon from "./assets/unkown.png";

export const font = "futura-pt-condensed, sans-serif;";

export const icons = {
  1: WarehouseIcon,
  2: RailyardIcon,
  3: WorkshopIcon,
  4: DropYardIcon,
  5: UnkownIcon,
};

export const strokeColor = {
  1: "#2196f3",
  2: "#F13D35",
};
