import { createReducer } from "@reduxjs/toolkit";
import { NodeType } from "../../entities/nodeType";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteNodeTypeSuccess,
  getNodeTypeByIdSuccess,
  getNodeTypesSearchMetadataSuccess,
  getNodeTypesSuccess,
  setSelectedFlag,
  setSelectedNodeTypeID,
} from "./nodeTypeActions";
import { NodeTypeState } from "./nodeTypeState";

function initState(): NodeTypeState {
  const { nodeType: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    nodeTypes: {},
    searchMetadata: undefined,
    selectedNodeTypeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
    selectedNodeTypes: {},
    selectedFlags: {},
  };
}

export const nodeTypeReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedNodeTypeID, (state, action) => {
    const nodeTypeID = action.payload.nodeTypeID;
    const selected = action.payload.selected;
    state.selectedNodeTypeID = nodeTypeID;
    if (nodeTypeID && selected != undefined)
      state.selectedNodeTypes[nodeTypeID] = selected;
    return state;
  });
  builder.addCase(setSelectedFlag, (state, action) => {
    const flag = action.payload.flag;
    const selected = action.payload.selected;
    if (flag && selected != undefined) state.selectedFlags[flag] = selected;
    return state;
  });
  builder.addCase(getNodeTypesSuccess, (state, action) => {
    const nodeTypes = action.payload.nodeTypes;
    state.nodeTypes = arrayToMap<NodeType>(nodeTypes);
    return state;
  });
  builder.addCase(getNodeTypesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.nodeTypesSearchMetadata;
    return state;
  });
  builder.addCase(deleteNodeTypeSuccess, (state, action) => {
    const nodeTypeID = action.payload.id;
    if (state.nodeTypes[nodeTypeID]) {
      delete state.nodeTypes[nodeTypeID];
    }
    return state;
  });
  builder.addCase(getNodeTypeByIdSuccess, (state, action) => {
    const nodeType = action.payload.nodeType;
    if (nodeType) {
      const nodeTypes = { ...state.nodeTypes };
      nodeTypes[nodeType.id] = nodeType;
      state.nodeTypes = nodeTypes;
    }
    return state;
  });
});
