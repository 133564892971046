import { getCurrentUser, userSigninSuccess } from "@nerdjs/account-kit";
import { showNotification } from "@nerdjs/nerd-ui";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppConfig } from "../environement";
import {
  nodeMouseClickAction,
  nodeMouseOutAction,
  nodeMouseOverAction,
  resetMap,
  setMarkersAction,
  setPolylinesAction,
} from "./map/mapActions";
import { getNodesSuccess } from "./node/nodeActions";
import { store } from "./store";

import { icons, strokeColor } from "../constants";
import { LegTypeLeg } from "../entities/legTypeLeg";
import { NodeTypeNode } from "../entities/nodeTypeNode";
import { mapToArray } from "../helpers/reducerHelpers";
import { getLegs, getLegsSuccess } from "./leg/legActions";
import {
  getLegTypeLegs,
  getLegTypeLegsSuccess,
} from "./legTypeLeg/legTypeLegActions";
import {
  getNodeTypeNodes,
  getNodeTypeNodesSuccess,
} from "./nodeTypeNode/nodeTypeNodeActions";
import {
  setSelectedFlag,
  setSelectedNodeTypeID,
} from "./nodeType/nodeTypeActions";
import { Node } from "../entities/node";

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // Do stuff
    console.log(`[mainMiddleware]: mainMiddleware sees action:`, api);
    console.log(action);

    const state = store.getState();
    const markers = state.mapState.markers;
    const polylines = state.mapState.polylines;
    const nodes = state.nodeState.nodes;
    const legs = state.legState.legs;
    const map = state.mapState.map;
    const selectedNodes = state.nodeState.selectedNodes;
    const selectedFlags = state.nodeTypeState.selectedFlags;
    const hasSelection = selectedNodes.length > 0;
    const lastAddedNodeID =
      selectedNodes.length > 0
        ? selectedNodes[selectedNodes.length - 1]
        : undefined;

    if (action.type == nodeMouseOverAction) {
      const marker: Marker = action.payload.marker;
      // we hightlight connected polylines & neighboor nodes.
      console.log("nodeMouseOverAction");
      highlightMarker(marker);
    }

    if (action.type == nodeMouseOutAction) {
      console.log("nodeMouseOutAction");
      restoreHighlight();
    }

    if (action.type == setSelectedNodeTypeID) {
      const nodeTypeID = action.payload.nodeTypeID;
      const selected = action.payload.selected;
      const selection = {
        ...state.nodeTypeState.selectedNodeTypes,
        [nodeTypeID]: selected,
      };
      visibilityForMarkers(selection);
      visibilityForPolylines(selection, state.nodeTypeState.selectedFlags);
    }

    if (action.type == setSelectedFlag) {
      const flag = action.payload.flag;
      const selected = action.payload.selected;
      const selection = {
        ...state.nodeTypeState.selectedFlags,
        [flag]: selected,
      };
      visibilityForPolylines(state.nodeTypeState.selectedNodeTypes, selection);
    }

    if (action.type == getNodesSuccess) {
      store.dispatch(getLegs());
      store.dispatch(getNodeTypeNodes());
    }

    if (action.type == getLegsSuccess) {
      store.dispatch(getLegTypeLegs());
    }

    if (action.type == resetMap) {
      for (const key in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, key)) {
          const m = markers[key];
          m.setOpacity(1);
        }
      }

      for (const key in polylines) {
        if (Object.prototype.hasOwnProperty.call(polylines, key)) {
          const p = polylines[key];
          p.setOptions({ strokeOpacity: 0.1 });
        }
      }
    }

    if (action.type == getNodeTypeNodesSuccess && state.mapState.map) {
      const nodes = mapToArray(state.nodeState.nodes);
      const nodeTypeNodes: NodeTypeNode[] = action.payload.nodeTypeNodes;
      const markers: { [id: number]: Marker } = {};
      nodes.forEach((node) => {
        const nodeTypeID = nodeTypeNodes.find(
          (ntn) => ntn.nodeID === node.id
        )?.nodeTypeID;
        if (!nodeTypeID) return;
        const m: Marker = new google.maps.Marker({
          position: node.position(),
          map: state.mapState.map,
          icon: {
            url: icons[nodeTypeID],
            scale: 1,
          },
        });
        m.nodeID = node.id;
        m.addListener("click", () => {
          store.dispatch(nodeMouseClickAction(m));
        });
        m.addListener("mouseover", () => {
          store.dispatch(nodeMouseOverAction(m));
        });
        m.addListener("mouseout", () => {
          store.dispatch(nodeMouseOutAction(m));
        });
        markers[node.id] = m;
      });

      store.dispatch(setMarkersAction(markers));
    }

    if (action.type == getLegTypeLegsSuccess && state.mapState.map) {
      const legs = mapToArray(state.legState.legs);
      const legTypeLegs: LegTypeLeg[] = action.payload.legTypeLegs;
      const nodes = state.nodeState.nodes;
      const polylines: { [id: number]: Polyline } = {};
      legs.forEach((leg) => {
        const legTypeID = legTypeLegs.find(
          (ltl) => ltl.legID === leg.id
        )?.legTypeID;
        if (!legTypeID) return;

        const origin = leg.node1ID && nodes[leg.node1ID];
        const destination = leg.node2ID && nodes[leg.node2ID];
        if (!origin || !destination) {
          console.log(leg, origin, destination);
          return;
        }

        const path = [origin.position(), destination.position()];

        const p: Polyline = new google.maps.Polyline({
          path,
          geodesic: true,
          strokeColor: strokeColor[legTypeID],
          strokeOpacity: 0.1,
          strokeWeight: 2,
          map: state.mapState.map,
        });
        p.legID = leg.id;
        p.node1ID = leg.node1ID;
        p.node2ID = leg.node2ID;
        polylines[leg.id] = p;
      });

      store.dispatch(setPolylinesAction(polylines));
    }

    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      if (status === 401) {
        if (
          action.payload.originAction.payload.request.url ===
          AppConfig.api.currentUser
        ) {
          store.dispatch(userSigninSuccess(undefined));
        } else {
          store.dispatch(getCurrentUser());
        }
      }

      if (
        action.payload.originAction.payload.request.url !==
        AppConfig.api.currentUser
      )
        store.dispatch(
          showNotification({
            notification: {
              uuid: uuid,
              title: `${status} - ${description}`,
              body: message,
              severity: status > 300 && status < 500 ? "warning" : "error",
              autohide: true,
            },
          })
        );
    }

    // helpers
    function restoreHighlight() {
      for (const key in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, key)) {
          const m = markers[key];
          if (!hasSelection) {
            m.setOpacity(1);
          } else {
            if (selectedNodes.indexOf(m.nodeID!) >= 0) {
              m.setOpacity(1);
            } else {
              m.setOpacity(0.1);
            }
          }
        }
      }

      for (const key in polylines) {
        if (Object.prototype.hasOwnProperty.call(polylines, key)) {
          const p = polylines[key];
          const node1ID = p.node1ID!;
          const node2ID = p.node2ID!;
          if (
            (selectedNodes.indexOf(node1ID) >= 0 &&
              selectedNodes.indexOf(node2ID) >= 0) ||
            lastAddedNodeID === node1ID ||
            lastAddedNodeID === node2ID
          ) {
            p.setOptions({ strokeOpacity: 1 });
          } else {
            p.setOptions({ strokeOpacity: 0.1 });
          }
        }
      }
    }

    function highlightMarker(marker: Marker) {
      for (const key in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, key)) {
          const m = markers[key];
          if (
            m.nodeID === marker.nodeID ||
            selectedNodes.indexOf(m.nodeID!) >= 0
          ) {
            m.setOpacity(1);
            m.setZIndex(1000);
          } else {
            m.setOpacity(0.1);
            m.setZIndex(1);
          }
        }
      }

      const polylines = getPolylinesForNode(marker.nodeID!);
      for (const key in polylines) {
        if (Object.prototype.hasOwnProperty.call(polylines, key)) {
          const p = polylines[key];
          const marker1 = getMarkerForNode(p.node1ID!);
          const marker2 = getMarkerForNode(p.node2ID!);
          marker1!.setOpacity(1);
          marker1!.setZIndex(1000);
          marker2!.setOpacity(1);
          marker2!.setZIndex(1000);
          if (
            p.node1ID === marker.nodeID ||
            p.node2ID === marker.nodeID ||
            selectedNodes.indexOf(p.node1ID!) >= 0 ||
            selectedNodes.indexOf(p.node2ID!) >= 0
          ) {
            p.setOptions({ strokeOpacity: 1 });
          } else {
            p.setOptions({ strokeOpacity: 0.1 });
          }
        }
      }
    }

    function visibilityForMarkers(selectedNodeTypes: {
      [id: number]: boolean;
    }) {
      for (const key in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, key)) {
          const marker = markers[key];
          const node = nodes[marker.nodeID!];
          if (selectedNodeTypes[node.nodeTypeID!] != false) {
            marker.setMap(map!);
          } else {
            marker.setMap(null);
          }
        }
      }
    }

    function visibilityForPolylines(
      selectedNodeTypes: {
        [id: number]: boolean;
      },
      selectedFlags: {
        [id: number]: boolean;
      }
    ) {
      for (const key in polylines) {
        if (Object.prototype.hasOwnProperty.call(polylines, key)) {
          const polyline = polylines[key];
          const leg = legs[polyline.legID!];
          const node1 = nodes[polyline.node1ID!];
          const node2 = nodes[polyline.node2ID!];
          const allFlags = leg.costs?.reduce<string[]>((a, i) => {
            if (a.indexOf(i.flagName) === -1) a.push(i.flagName);
            return a;
          }, []);
          let visible = false;
          allFlags?.forEach((f) => {
            if (selectedFlags[f] ?? true) visible = true;
          });

          if (
            selectedNodeTypes[node1.nodeTypeID!] != false ||
            selectedNodeTypes[node2.nodeTypeID!] != false
          ) {
            polyline.setMap(map!);
          } else {
            polyline.setMap(null);
          }

          if (!visible) polyline.setMap(null);
        }
      }
    }

    function getPolylinesForNode(nodeID: number): Polyline[] {
      const ret: Polyline[] = [];
      for (const key in polylines) {
        if (Object.prototype.hasOwnProperty.call(polylines, key)) {
          const polyline = polylines[key];
          if (polyline.node1ID === nodeID || polyline.node2ID === nodeID) {
            ret.push(polyline);
          }
        }
      }
      return ret;
    }

    function getMarkerForNode(nodeID: number): Marker | undefined {
      for (const key in markers) {
        if (Object.prototype.hasOwnProperty.call(markers, key)) {
          const marker = markers[key];
          if (marker.nodeID === nodeID) return marker;
        }
      }

      return undefined;
    }

    return next(action);
  };

export interface Polyline extends google.maps.Polyline {
  legID?: number;
  node1ID?: number;
  node2ID?: number;
}

export interface Marker extends google.maps.Marker {
  nodeID?: number;
}
