import { createReducer } from "@reduxjs/toolkit";
import { NodeTypeNode } from "../../entities/nodeTypeNode";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteNodeTypeNodeSuccess,
  getNodeTypeNodeByIdSuccess,
  getNodeTypeNodesSearchMetadataSuccess,
  getNodeTypeNodesSuccess,
  setSelectedNodeTypeNodeID,
} from "./nodeTypeNodeActions";
import { NodeTypeNodeState } from "./nodeTypeNodeState";

function initState(): NodeTypeNodeState {
  const { nodeTypeNode: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    nodeTypeNodes: {},
    searchMetadata: undefined,
    selectedNodeTypeNodeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const nodeTypeNodeReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedNodeTypeNodeID, (state, action) => {
    state.selectedNodeTypeNodeID = action.payload.nodeTypeNodeID;
    return state;
  });
  builder.addCase(getNodeTypeNodesSuccess, (state, action) => {
    const nodeTypeNodes = action.payload.nodeTypeNodes;
    state.nodeTypeNodes = arrayToMap<NodeTypeNode>(nodeTypeNodes);
    return state;
  });
  builder.addCase(getNodeTypeNodesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.nodeTypeNodesSearchMetadata;
    return state;
  });
  builder.addCase(deleteNodeTypeNodeSuccess, (state, action) => {
    const nodeTypeNodeID = action.payload.id;
    if (state.nodeTypeNodes[nodeTypeNodeID]) {
      delete state.nodeTypeNodes[nodeTypeNodeID];
    }
    return state;
  });
  builder.addCase(getNodeTypeNodeByIdSuccess, (state, action) => {
    const nodeTypeNode = action.payload.nodeTypeNode;
    if (nodeTypeNode) {
      const nodeTypeNodes = { ...state.nodeTypeNodes };
      nodeTypeNodes[nodeTypeNode.id] = nodeTypeNode;
      state.nodeTypeNodes = nodeTypeNodes;
    }
    return state;
  });
});
