import LegType_Entity from "./legType";
export default class LegType extends LegType_Entity {
  static fromList(legTypesJSON: unknown): Array<LegType> {
    const legTypes: LegType[] = [];
    if (legTypesJSON)
      Array.isArray(legTypesJSON) &&
        legTypesJSON.forEach((legTypeJSON) => {
          legTypes.push(new LegType(legTypeJSON));
        });
    return legTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
