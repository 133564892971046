import Leg_Entity from "./leg";
export default class Leg extends Leg_Entity {
  static fromList(legsJSON: unknown): Array<Leg> {
    const legs: Leg[] = [];
    if (legsJSON)
      Array.isArray(legsJSON) &&
        legsJSON.forEach((legJSON) => {
          legs.push(new Leg(legJSON));
        });
    return legs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
