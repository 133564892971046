import { Cost_Entity } from "../cost";

/**
 * Leg_Entity type guard.
 *
 * @param {any} legJson Leg object from API
 * @returns {boolean} Return true if type is Leg_Entity
 */

export default class Leg_Entity {
  static requiredFields = ["costs"];

  constructor(legJson: Leg_Entity) {
    try {
      this.id = legJson.id;
      this.name = legJson.name;
      this.node1ID = legJson.node1ID;
      this.node2ID = legJson.node2ID;
      this.companyID = legJson.companyID;
      this.testVersion = legJson.testVersion;
      this.legTypeID = legJson.legTypeID;
      this.costs = legJson.costs;
    } catch {
      throw new Error(
        "Failed to create new instance of ${ Leg_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  node1ID?: number;
  node2ID?: number;
  companyID?: number;
  legTypeID?: number;
  testVersion?: boolean;
  costs: Cost_Entity[];
}
