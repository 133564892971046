import { Box } from "@mui/material";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { clearNotification, NerdNotifier } from "@nerdjs/nerd-ui";
import {
  ReactChild,
  ReactChildren,
  ReactElement,
  ReactNode,
  useEffect,
} from "react";

import { CssVarsProvider } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { getTheme } from "../config/themeConfig";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const theme = getTheme();

  return (
    <CssVarsProvider theme={theme}>
      <NotifierWrapper>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <AppRouter />
        </Box>
      </NotifierWrapper>
    </CssVarsProvider>
  );
}

const NotifierWrapper = ({
  children,
}: {
  children: (ReactChild | ReactChildren) & ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
