import NodeTypeNode_Entity from "./nodeTypeNode";
export default class NodeTypeNode extends NodeTypeNode_Entity {
  static fromList(nodeTypeNodesJSON: unknown): Array<NodeTypeNode> {
    const nodeTypeNodes: NodeTypeNode[] = [];
    if (nodeTypeNodesJSON)
      Array.isArray(nodeTypeNodesJSON) &&
        nodeTypeNodesJSON.forEach((nodeTypeNodeJSON) => {
          nodeTypeNodes.push(new NodeTypeNode(nodeTypeNodeJSON));
        });
    return nodeTypeNodes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
