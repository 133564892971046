/**
 * LegType_Entity type guard.
 *
 * @param {any} legTypeJson LegType object from API
 * @returns {boolean} Return true if type is LegType_Entity
 */
function validator(legTypeJson: unknown): legTypeJson is LegType_Entity {
  if (typeof legTypeJson === "object" && legTypeJson != null) {
    LegType_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(legTypeJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class LegType_Entity {
  static requiredFields = [];

  constructor(legTypeJson: unknown) {
    if (validator(legTypeJson)) {
      this.id = legTypeJson.id;
      this.name = legTypeJson.name;
    } else {
      throw new Error(
        "Failed to create new instance of ${ LegType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
}
