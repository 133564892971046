/**
 * Node_Entity type guard.
 *
 * @param {any} nodeJson Node object from API
 * @returns {boolean} Return true if type is Node_Entity
 */
function validator(nodeJson: unknown): nodeJson is Node_Entity {
  if (typeof nodeJson === "object" && nodeJson != null) {
    Node_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(nodeJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Node_Entity {
  static requiredFields = [];

  constructor(nodeJson: unknown) {
    if (validator(nodeJson)) {
      this.id = nodeJson.id;
      this.name = nodeJson.name;
      this.radius = nodeJson.radius;
      this.latitude = nodeJson.latitude;
      this.longitude = nodeJson.longitude;
      this.scac = nodeJson.scac;
      this.nodeTypeID = nodeJson.nodeTypeID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Node_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
  radius?: number;
  latitude: number;
  longitude: number;
  scac?: string;
  nodeTypeID?: number;
}
