/**
 * LegTypeLeg_Entity type guard.
 *
 * @param {any} legTypeLegJson LegTypeLeg object from API
 * @returns {boolean} Return true if type is LegTypeLeg_Entity
 */
function validator(
  legTypeLegJson: unknown
): legTypeLegJson is LegTypeLeg_Entity {
  if (typeof legTypeLegJson === "object" && legTypeLegJson != null) {
    LegTypeLeg_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(legTypeLegJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class LegTypeLeg_Entity {
  static requiredFields = [];

  constructor(legTypeLegJson: unknown) {
    if (validator(legTypeLegJson)) {
      this.id = legTypeLegJson.id;
      this.legID = legTypeLegJson.legID;
      this.legTypeID = legTypeLegJson.legTypeID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ LegTypeLeg_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  legID: number;
  legTypeID: number;
}
