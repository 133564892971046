import { createReducer } from "@reduxjs/toolkit";
import { Leg } from "../../entities/leg";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import { getLegTypeLegsSuccess } from "../legTypeLeg/legTypeLegActions";
import {
  deleteLegSuccess,
  getLegByIdSuccess,
  getLegsSearchMetadataSuccess,
  getLegsSuccess,
  setSelectedLegID,
} from "./legActions";
import { LegState } from "./legState";

function initState(): LegState {
  const { leg: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    legs: {},
    searchMetadata: undefined,
    selectedLegID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const legReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedLegID, (state, action) => {
    state.selectedLegID = action.payload.legID;
    return state;
  });
  builder.addCase(getLegsSuccess, (state, action) => {
    const legs = action.payload.legs;
    state.legs = arrayToMap<Leg>(legs);
    return state;
  });
  builder.addCase(getLegsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.legsSearchMetadata;
    return state;
  });
  builder.addCase(deleteLegSuccess, (state, action) => {
    const legID = action.payload.id;
    if (state.legs[legID]) {
      delete state.legs[legID];
    }
    return state;
  });
  builder.addCase(getLegByIdSuccess, (state, action) => {
    const leg = action.payload.leg;
    if (leg) {
      const legs = { ...state.legs };
      legs[leg.id] = leg;
      state.legs = legs;
    }
    return state;
  });
  builder.addCase(getLegTypeLegsSuccess, (state, action) => {
    const legTypeLegs = action.payload.legTypeLegs;
    for (const key in state.legs) {
      if (Object.prototype.hasOwnProperty.call(state.legs, key)) {
        const leg = state.legs[key];
        const legTypeLeg = legTypeLegs.find((ltl) => ltl.legID === leg.id);
        if (legTypeLeg) {
          state.legs[key].legTypeID = legTypeLeg.legTypeID;
        }
      }
    }
    return state;
  });
});
