import { createReducer } from "@reduxjs/toolkit";
import { Node } from "../../entities/node";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import { nodeMouseClickAction, resetMap } from "../map/mapActions";
import { getNodeTypeNodesSuccess } from "../nodeTypeNode/nodeTypeNodeActions";
import {
  deleteNodeSuccess,
  getNodeByIdSuccess,
  getNodesSearchMetadataSuccess,
  getNodesSuccess,
} from "./nodeActions";
import { NodeState } from "./nodeState";

function initState(): NodeState {
  const { node: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    nodes: {},
    searchMetadata: undefined,
    selectedNodes: [],
    selectedNodeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const nodeReducer = createReducer(initState(), (builder) => {
  builder.addCase(resetMap, (state, action) => {
    state.selectedNodes = [];
    return state;
  });
  builder.addCase(nodeMouseClickAction, (state, action) => {
    const marker = action.payload.marker;
    const nodeID = marker.nodeID!;
    const index = state.selectedNodes.indexOf(nodeID);
    if (index >= 0) {
      state.selectedNodes.splice(index, 1);
    } else {
      state.selectedNodes.push(nodeID);
    }
    return state;
  });
  builder.addCase(getNodesSuccess, (state, action) => {
    const nodes = action.payload.nodes;
    state.nodes = arrayToMap<Node>(nodes);
    return state;
  });
  builder.addCase(getNodesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.nodesSearchMetadata;
    return state;
  });
  builder.addCase(deleteNodeSuccess, (state, action) => {
    const nodeID = action.payload.id;
    if (state.nodes[nodeID]) {
      delete state.nodes[nodeID];
    }
    return state;
  });
  builder.addCase(getNodeByIdSuccess, (state, action) => {
    const node = action.payload.node;
    if (node) {
      const nodes = { ...state.nodes };
      nodes[node.id] = node;
      state.nodes = nodes;
    }
    return state;
  });
  builder.addCase(getNodeTypeNodesSuccess, (state, action) => {
    const nodeTypeNodes = action.payload.nodeTypeNodes;
    for (const key in state.nodes) {
      if (Object.prototype.hasOwnProperty.call(state.nodes, key)) {
        const node = state.nodes[key];
        const nodeTypeNode = nodeTypeNodes.find(
          (ntn) => ntn.nodeID === node.id
        );
        if (nodeTypeNode) {
          state.nodes[key].nodeTypeID = nodeTypeNode.nodeTypeID;
        }
      }
    }
    return state;
  });
});
