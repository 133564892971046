import {
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
} from "@mui/joy";
import { Box, Paper, Popover } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Logo from "../assets/logo.png";
import { icons } from "../constants";
import { useAppSelector } from "../hooks/hooks";
import { setDrawerOpenAction } from "../redux/appStatus/appStatusActions";
import { drawerOpenSelector } from "../redux/appStatus/appStatusSelector";
import { initMapAction, resetMap } from "../redux/map/mapActions";
import { mapSelector } from "../redux/map/mapSelector";
import { getNodes } from "../redux/node/nodeActions";
import {
  getNodeTypes,
  setSelectedFlag,
  setSelectedNodeTypeID,
} from "../redux/nodeType/nodeTypeActions";
import { nodeTypesSelector } from "../redux/nodeType/nodeTypeSelector";

const flags = [
  "LTL",
  "TL",
  "Standard IM Service",
  "Expedited IM Service",
  "Empty Load",
];

export default function ServiceMap() {
  const ref = useRef<HTMLDivElement>(null);
  const map = useAppSelector(mapSelector);
  const nodeTypes = useAppSelector(nodeTypesSelector);
  const drawerOpen = useAppSelector(drawerOpenSelector);
  const selectedNodeTypes = useAppSelector(
    (s) => s.nodeTypeState.selectedNodeTypes
  );
  const selectedFlags = useAppSelector((s) => s.nodeTypeState.selectedFlags);
  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref.current && map === undefined) {
      dispatch(initMapAction(ref.current));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      dispatch(getNodes());
      dispatch(getNodeTypes());
    }
  }, [map]);

  return (
    <Box sx={{ flex: 1, display: "flex", position: "relative" }}>
      <IconButton
        ref={buttonRef}
        color="danger"
        onClick={() => dispatch(setDrawerOpenAction(!drawerOpen))}
        sx={{ position: "absolute", top: 16, left: 16, zIndex: 9999 }}
      >
        <i className="fa-solid fa-bars"></i>
      </IconButton>
      <IconButton
        ref={buttonRef}
        color="danger"
        onClick={() => dispatch(resetMap())}
        sx={{ position: "absolute", top: 16, left: 65, zIndex: 9999 }}
      >
        <i className="fa-solid fa-arrows-rotate"></i>
      </IconButton>
      <Popover
        open={drawerOpen}
        anchorEl={buttonRef.current}
        sx={{ zIndex: 9999 }}
        onClose={() => dispatch(setDrawerOpenAction(false))}
      >
        <Box>
          <List>
            {nodeTypes.map((p) => (
              <ListItem
                key={p.id}
                endAction={<img src={icons[p.id]} style={{ height: 40 }} />}
              >
                <ListItemButton
                  onClick={() => {
                    dispatch(
                      setSelectedNodeTypeID(
                        p.id,
                        !(selectedNodeTypes[p.id] ?? true)
                      )
                    );
                  }}
                >
                  <Box>
                    <ListItemDecorator>
                      <Checkbox
                        checked={selectedNodeTypes[p.id] ?? true}
                        color="danger"
                      />
                    </ListItemDecorator>
                    {p.name}
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider>Leg Types</Divider>
            {flags.map((f) => (
              <ListItem key={f}>
                <ListItemButton
                  onClick={() => {
                    dispatch(setSelectedFlag(f, !(selectedFlags[f] ?? true)));
                  }}
                >
                  <Box>
                    <ListItemDecorator>
                      <Checkbox
                        checked={selectedFlags[f] ?? true}
                        color="danger"
                      />
                    </ListItemDecorator>
                    {f}
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
      <img
        src={Logo}
        style={{ position: "absolute", top: 16, right: 16, zIndex: 9999 }}
      />
      <Paper ref={ref} elevation={2} sx={{ flex: 1 }}></Paper>
    </Box>
  );
}
