import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { LegTypeLeg } from "../../entities/legTypeLeg";

export const setSelectedLegTypeLegID = createAction(
  "setSelectedLegTypeLegID",
  function prepare(legTypeLegID?: number) {
    return {
      payload: {
        legTypeLegID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypeLegs = createAction(
  "v3/sales/legTypeLeg/getLegTypeLegs",
  function prepare() {
    const request = new GetAction(`/v3/sales/legTypeLegs`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypeLegs = LegTypeLeg.fromList(json);
      dispatch(getLegTypeLegsSuccess(legTypeLegs));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypeLegsSuccess = createAction(
  "v3/sales/legTypeLeg/getLegTypeLegSuccess",
  function prepare(legTypeLegs: LegTypeLeg[]) {
    return {
      payload: {
        legTypeLegs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypeLegsByFilter = createAction(
  "v3/sales/legTypeLeg/getLegTypeLegsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/legTypeLegs`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypeLegs = LegTypeLeg.fromList(json);
      dispatch(getLegTypeLegsByFilterSuccess(legTypeLegs));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypeLegsByFilterSuccess = createAction(
  "v3/sales/legTypeLeg/getLegTypeLegsByFilterSuccess",
  function prepare(legTypeLegs: LegTypeLeg[]) {
    return {
      payload: {
        legTypeLegs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypeLegById = createAction(
  "v3/sales/legTypeLeg/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/legTypeLegs/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypeLeg = new LegTypeLeg(json);
      dispatch(getLegTypeLegByIdSuccess(legTypeLeg));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypeLegByIdSuccess = createAction(
  "v3/sales/legTypeLeg/getLegTypeLegByIdSuccess",
  function prepare(legTypeLeg?: LegTypeLeg) {
    return {
      payload: {
        legTypeLeg,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteLegTypeLeg = createAction(
  "v3/sales/removeLegTypeLegById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/legTypeLegs/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteLegTypeLegSuccess = createAction(
  "v3/sales/legTypeLegs/deleteLegTypeLegSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateLegTypeLeg = createAction(
  "v3/sales/legTypeLegs/updateLegTypeLeg",
  function prepare(id: number, body: Partial<LegTypeLeg>) {
    const request = new PutAction(`/v3/sales/legTypeLegs/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateLegTypeLegSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateLegTypeLegSuccess = createAction(
  "v3/sales/legTypeLegs/updateLegTypeLegSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createLegTypeLeg = createAction(
  "v3/sales/legTypeLegs/createLegTypeLeg",
  function prepare(body: Partial<LegTypeLeg>) {
    const request = new PostAction(`/v3/sales/legTypeLegs`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypeLeg = new LegTypeLeg(json);
      dispatch(createLegTypeLegSuccess(legTypeLeg));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createLegTypeLegSuccess = createAction(
  "v3/sales/legTypeLegs/createLegTypeLegSuccess",
  function prepare(legTypeLeg: LegTypeLeg) {
    return {
      payload: {
        legTypeLeg,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypeLegsSearchMetadata = createAction(
  "v3/sales/getLegTypeLegsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/legTypeLegs/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getLegTypeLegsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypeLegsSearchMetadataSuccess = createAction(
  "v3/sales/getLegTypeLegsSearchMetadataSuccess",
  function prepare(legTypeLegsSearchMetadata: unknown) {
    return {
      payload: {
        legTypeLegsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
