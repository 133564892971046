/**
 * NodeTypeNode_Entity type guard.
 *
 * @param {any} nodeTypeNodeJson NodeTypeNode object from API
 * @returns {boolean} Return true if type is NodeTypeNode_Entity
 */
function validator(
  nodeTypeNodeJson: unknown
): nodeTypeNodeJson is NodeTypeNode_Entity {
  if (typeof nodeTypeNodeJson === "object" && nodeTypeNodeJson != null) {
    NodeTypeNode_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(nodeTypeNodeJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class NodeTypeNode_Entity {
  static requiredFields = [];

  constructor(nodeTypeNodeJson: unknown) {
    if (validator(nodeTypeNodeJson)) {
      this.id = nodeTypeNodeJson.id;
      this.nodeID = nodeTypeNodeJson.nodeID;
      this.nodeTypeID = nodeTypeNodeJson.nodeTypeID;
    } else {
      throw new Error(
        "Failed to create new instance of ${ NodeTypeNode_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  nodeID: number;
  nodeTypeID: number;
}
