import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { NodeTypeNode } from "../../entities/nodeTypeNode";

export const setSelectedNodeTypeNodeID = createAction(
  "setSelectedNodeTypeNodeID",
  function prepare(nodeTypeNodeID?: number) {
    return {
      payload: {
        nodeTypeNodeID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypeNodes = createAction(
  "v3/sales/nodeTypeNode/getNodeTypeNodes",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodeTypeNodes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypeNodes = NodeTypeNode.fromList(json);
      dispatch(getNodeTypeNodesSuccess(nodeTypeNodes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypeNodesSuccess = createAction(
  "v3/sales/nodeTypeNode/getNodeTypeNodeSuccess",
  function prepare(nodeTypeNodes: NodeTypeNode[]) {
    return {
      payload: {
        nodeTypeNodes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypeNodesByFilter = createAction(
  "v3/sales/nodeTypeNode/getNodeTypeNodesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/nodeTypeNodes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypeNodes = NodeTypeNode.fromList(json);
      dispatch(getNodeTypeNodesByFilterSuccess(nodeTypeNodes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypeNodesByFilterSuccess = createAction(
  "v3/sales/nodeTypeNode/getNodeTypeNodesByFilterSuccess",
  function prepare(nodeTypeNodes: NodeTypeNode[]) {
    return {
      payload: {
        nodeTypeNodes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypeNodeById = createAction(
  "v3/sales/nodeTypeNode/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/nodeTypeNodes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypeNode = new NodeTypeNode(json);
      dispatch(getNodeTypeNodeByIdSuccess(nodeTypeNode));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypeNodeByIdSuccess = createAction(
  "v3/sales/nodeTypeNode/getNodeTypeNodeByIdSuccess",
  function prepare(nodeTypeNode?: NodeTypeNode) {
    return {
      payload: {
        nodeTypeNode,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteNodeTypeNode = createAction(
  "v3/sales/removeNodeTypeNodeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/nodeTypeNodes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteNodeTypeNodeSuccess = createAction(
  "v3/sales/nodeTypeNodes/deleteNodeTypeNodeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateNodeTypeNode = createAction(
  "v3/sales/nodeTypeNodes/updateNodeTypeNode",
  function prepare(id: number, body: Partial<NodeTypeNode>) {
    const request = new PutAction(`/v3/sales/nodeTypeNodes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateNodeTypeNodeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateNodeTypeNodeSuccess = createAction(
  "v3/sales/nodeTypeNodes/updateNodeTypeNodeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createNodeTypeNode = createAction(
  "v3/sales/nodeTypeNodes/createNodeTypeNode",
  function prepare(body: Partial<NodeTypeNode>) {
    const request = new PostAction(`/v3/sales/nodeTypeNodes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypeNode = new NodeTypeNode(json);
      dispatch(createNodeTypeNodeSuccess(nodeTypeNode));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createNodeTypeNodeSuccess = createAction(
  "v3/sales/nodeTypeNodes/createNodeTypeNodeSuccess",
  function prepare(nodeTypeNode: NodeTypeNode) {
    return {
      payload: {
        nodeTypeNode,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypeNodesSearchMetadata = createAction(
  "v3/sales/getNodeTypeNodesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodeTypeNodes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getNodeTypeNodesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypeNodesSearchMetadataSuccess = createAction(
  "v3/sales/getNodeTypeNodesSearchMetadataSuccess",
  function prepare(nodeTypeNodesSearchMetadata: unknown) {
    return {
      payload: {
        nodeTypeNodesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
