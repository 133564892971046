import Node_Entity from "./node";
export default class Node extends Node_Entity {
  static fromList(nodesJSON: unknown): Array<Node> {
    const nodes: Node[] = [];
    if (nodesJSON)
      Array.isArray(nodesJSON) &&
        nodesJSON.forEach((nodeJSON) => {
          nodes.push(new Node(nodeJSON));
        });
    return nodes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  position() {
    return {
      lat: this.latitude,
      lng: this.longitude,
    };
  }
}
