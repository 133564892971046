import LegTypeLeg_Entity from "./legTypeLeg";
export default class LegTypeLeg extends LegTypeLeg_Entity {
  static fromList(legTypeLegsJSON: unknown): Array<LegTypeLeg> {
    const legTypeLegs: LegTypeLeg[] = [];
    if (legTypeLegsJSON)
      Array.isArray(legTypeLegsJSON) &&
        legTypeLegsJSON.forEach((legTypeLegJSON) => {
          legTypeLegs.push(new LegTypeLeg(legTypeLegJSON));
        });
    return legTypeLegs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
