import NodeType_Entity from "./nodeType";
export default class NodeType extends NodeType_Entity {
  static fromList(nodeTypesJSON: unknown): Array<NodeType> {
    const nodeTypes: NodeType[] = [];
    if (nodeTypesJSON)
      Array.isArray(nodeTypesJSON) &&
        nodeTypesJSON.forEach((nodeTypeJSON) => {
          nodeTypes.push(new NodeType(nodeTypeJSON));
        });
    return nodeTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
