import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Leg, Leg_Entity } from "../../entities/leg";

export const setSelectedLegID = createAction(
  "setSelectedLegID",
  function prepare(legID?: number) {
    return {
      payload: {
        legID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegs = createAction("v3/sales/leg/getLegs", function prepare() {
  const request = new GetAction(`/v3/sales/legs`);

  request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
    const legs = Leg.fromList(json);
    dispatch(getLegsSuccess(legs));
  };

  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
      request,
    },
  };
});

export const getLegsSuccess = createAction(
  "v3/sales/leg/getLegSuccess",
  function prepare(legs: Leg[]) {
    return {
      payload: {
        legs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegsByFilter = createAction(
  "v3/sales/leg/getLegsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/legs`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legs = Leg.fromList(json);
      dispatch(getLegsByFilterSuccess(legs));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegsByFilterSuccess = createAction(
  "v3/sales/leg/getLegsByFilterSuccess",
  function prepare(legs: Leg[]) {
    return {
      payload: {
        legs,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegById = createAction(
  "v3/sales/leg/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/legs/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: Leg_Entity) => {
      const leg = new Leg(json);
      dispatch(getLegByIdSuccess(leg));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegByIdSuccess = createAction(
  "v3/sales/leg/getLegByIdSuccess",
  function prepare(leg?: Leg) {
    return {
      payload: {
        leg,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteLeg = createAction(
  "v3/sales/removeLegById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/legs/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteLegSuccess = createAction(
  "v3/sales/legs/deleteLegSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateLeg = createAction(
  "v3/sales/legs/updateLeg",
  function prepare(id: number, body: Partial<Leg>) {
    const request = new PutAction(`/v3/sales/legs/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateLegSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateLegSuccess = createAction(
  "v3/sales/legs/updateLegSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createLeg = createAction(
  "v3/sales/legs/createLeg",
  function prepare(body: Partial<Leg>) {
    const request = new PostAction(`/v3/sales/legs`, body);

    request.decodeResponse = (dispatch: Dispatch, json: Leg_Entity) => {
      const leg = new Leg(json);
      dispatch(createLegSuccess(leg));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createLegSuccess = createAction(
  "v3/sales/legs/createLegSuccess",
  function prepare(leg: Leg) {
    return {
      payload: {
        leg,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegsSearchMetadata = createAction(
  "v3/sales/getLegsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/legs/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getLegsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegsSearchMetadataSuccess = createAction(
  "v3/sales/getLegsSearchMetadataSuccess",
  function prepare(legsSearchMetadata: unknown) {
    return {
      payload: {
        legsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
