/**
 * NodeType_Entity type guard.
 *
 * @param {any} nodeTypeJson NodeType object from API
 * @returns {boolean} Return true if type is NodeType_Entity
 */
function validator(nodeTypeJson: unknown): nodeTypeJson is NodeType_Entity {
  if (typeof nodeTypeJson === "object" && nodeTypeJson != null) {
    NodeType_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(nodeTypeJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class NodeType_Entity {
  static requiredFields = [];

  constructor(nodeTypeJson: unknown) {
    if (validator(nodeTypeJson)) {
      this.id = nodeTypeJson.id;
      this.name = nodeTypeJson.name;
    } else {
      throw new Error(
        "Failed to create new instance of ${ NodeType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name?: string;
}
