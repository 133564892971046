import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { NodeType } from "../../entities/nodeType";

export const setSelectedNodeTypeID = createAction(
  "setSelectedNodeTypeID",
  function prepare(nodeTypeID?: number, selected?: boolean) {
    return {
      payload: {
        selected,
        nodeTypeID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedFlag = createAction(
  "setSelectedFlag",
  function prepare(flag?: string, selected?: boolean) {
    return {
      payload: {
        selected,
        flag,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypes = createAction(
  "v3/sales/nodeType/getNodeTypes",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodeTypes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypes = NodeType.fromList(json);
      dispatch(getNodeTypesSuccess(nodeTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypesSuccess = createAction(
  "v3/sales/nodeType/getNodeTypeSuccess",
  function prepare(nodeTypes: NodeType[]) {
    return {
      payload: {
        nodeTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypesByFilter = createAction(
  "v3/sales/nodeType/getNodeTypesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/nodeTypes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeTypes = NodeType.fromList(json);
      dispatch(getNodeTypesByFilterSuccess(nodeTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypesByFilterSuccess = createAction(
  "v3/sales/nodeType/getNodeTypesByFilterSuccess",
  function prepare(nodeTypes: NodeType[]) {
    return {
      payload: {
        nodeTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypeById = createAction(
  "v3/sales/nodeType/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/nodeTypes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeType = new NodeType(json);
      dispatch(getNodeTypeByIdSuccess(nodeType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypeByIdSuccess = createAction(
  "v3/sales/nodeType/getNodeTypeByIdSuccess",
  function prepare(nodeType?: NodeType) {
    return {
      payload: {
        nodeType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteNodeType = createAction(
  "v3/sales/removeNodeTypeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/nodeTypes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteNodeTypeSuccess = createAction(
  "v3/sales/nodeTypes/deleteNodeTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateNodeType = createAction(
  "v3/sales/nodeTypes/updateNodeType",
  function prepare(id: number, body: Partial<NodeType>) {
    const request = new PutAction(`/v3/sales/nodeTypes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateNodeTypeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateNodeTypeSuccess = createAction(
  "v3/sales/nodeTypes/updateNodeTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createNodeType = createAction(
  "v3/sales/nodeTypes/createNodeType",
  function prepare(body: Partial<NodeType>) {
    const request = new PostAction(`/v3/sales/nodeTypes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodeType = new NodeType(json);
      dispatch(createNodeTypeSuccess(nodeType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createNodeTypeSuccess = createAction(
  "v3/sales/nodeTypes/createNodeTypeSuccess",
  function prepare(nodeType: NodeType) {
    return {
      payload: {
        nodeType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeTypesSearchMetadata = createAction(
  "v3/sales/getNodeTypesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodeTypes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getNodeTypesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeTypesSearchMetadataSuccess = createAction(
  "v3/sales/getNodeTypesSearchMetadataSuccess",
  function prepare(nodeTypesSearchMetadata: unknown) {
    return {
      payload: {
        nodeTypesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
