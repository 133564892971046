import { createReducer } from "@reduxjs/toolkit";
import { LegTypeLeg } from "../../entities/legTypeLeg";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteLegTypeLegSuccess,
  getLegTypeLegByIdSuccess,
  getLegTypeLegsSearchMetadataSuccess,
  getLegTypeLegsSuccess,
  setSelectedLegTypeLegID,
} from "./legTypeLegActions";
import { LegTypeLegState } from "./legTypeLegState";

function initState(): LegTypeLegState {
  const { legTypeLeg: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    legTypeLegs: {},
    searchMetadata: undefined,
    selectedLegTypeLegID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const legTypeLegReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedLegTypeLegID, (state, action) => {
    state.selectedLegTypeLegID = action.payload.legTypeLegID;
    return state;
  });
  builder.addCase(getLegTypeLegsSuccess, (state, action) => {
    const legTypeLegs = action.payload.legTypeLegs;
    state.legTypeLegs = arrayToMap<LegTypeLeg>(legTypeLegs);
    return state;
  });
  builder.addCase(getLegTypeLegsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.legTypeLegsSearchMetadata;
    return state;
  });
  builder.addCase(deleteLegTypeLegSuccess, (state, action) => {
    const legTypeLegID = action.payload.id;
    if (state.legTypeLegs[legTypeLegID]) {
      delete state.legTypeLegs[legTypeLegID];
    }
    return state;
  });
  builder.addCase(getLegTypeLegByIdSuccess, (state, action) => {
    const legTypeLeg = action.payload.legTypeLeg;
    if (legTypeLeg) {
      const legTypeLegs = { ...state.legTypeLegs };
      legTypeLegs[legTypeLeg.id] = legTypeLeg;
      state.legTypeLegs = legTypeLegs;
    }
    return state;
  });
});
