import { accountKitReducer, AccountKitState } from "@nerdjs/account-kit";
import {
  networkMiddleware,
  networkReducer,
  NetworkState,
} from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { legReducer } from "./leg/legReducer";
import { LegState } from "./leg/legState";
import { legTypeReducer } from "./legType/legTypeReducer";
import { LegTypeState } from "./legType/legTypeState";
import { mainMiddleware } from "./mainMiddleware";
import { MapState } from "./map/mapState";
import { mapReducer } from "./map/mapReducer";
import { nodeReducer } from "./node/nodeReducer";
import { NodeState } from "./node/nodeState";
import { nodeTypeReducer } from "./nodeType/nodeTypeReducer";
import { NodeTypeState } from "./nodeType/nodeTypeState";
import { appStatusReducer } from "./appStatus/appStatusReducer";
import { AppStatusState } from "./appStatus/appStatusState";
import { LegTypeLegState } from "./legTypeLeg/legTypeLegState";
import { NodeTypeNodeState } from "./nodeTypeNode/nodeTypeNodeState";
import { nodeTypeNodeReducer } from "./nodeTypeNode/nodeTypeNodeReducer";
import { legTypeLegReducer } from "./legTypeLeg/legTypeLegReducer";

export interface AppState {
  nodeTypeState: NodeTypeState;
  nodeState: NodeState;
  legTypeState: LegTypeState;
  legState: LegState;
  mapState: MapState;
  legTypeLegState: LegTypeLegState;
  nodeTypeNodeState: NodeTypeNodeState;
  appStatusState: AppStatusState;

  accountKitState: AccountKitState;
  networkState: NetworkState;
}

export const store = createStore(
  combineReducers({
    nodeTypeState: nodeTypeReducer,
    nodeState: nodeReducer,
    legTypeState: legTypeReducer,
    legState: legReducer,
    mapState: mapReducer,
    appStatusState: appStatusReducer,
    nodeTypeNodeState: nodeTypeNodeReducer,
    legTypeLegState: legTypeLegReducer,

    // this defines the shape of our MainState
    accountKitState: accountKitReducer,
    notification: notificationReducer,
    networkState: networkReducer,
  }),
  applyMiddleware(mainMiddleware, networkMiddleware)
);

export type AppDispatch = typeof store.dispatch;
