import { createReducer } from "@reduxjs/toolkit";
import { setDrawerOpenAction } from "./appStatusActions";
import { AppStatusState } from "./appStatusState";

function initState(): AppStatusState {
  return {
    drawerOpen: false,
  };
}

export const appStatusReducer = createReducer(initState(), (builder) => {
  builder.addCase(setDrawerOpenAction, (state, action) => {
    state.drawerOpen = action.payload.open;
    return state;
  });
});
