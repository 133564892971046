import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { LegType } from "../../entities/legType";

export const setSelectedLegTypeID = createAction(
  "setSelectedLegTypeID",
  function prepare(legTypeID?: number) {
    return {
      payload: {
        legTypeID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypes = createAction(
  "v3/sales/legType/getLegTypes",
  function prepare() {
    const request = new GetAction(`/v3/sales/legTypes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypes = LegType.fromList(json);
      dispatch(getLegTypesSuccess(legTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypesSuccess = createAction(
  "v3/sales/legType/getLegTypeSuccess",
  function prepare(legTypes: LegType[]) {
    return {
      payload: {
        legTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypesByFilter = createAction(
  "v3/sales/legType/getLegTypesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/legTypes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legTypes = LegType.fromList(json);
      dispatch(getLegTypesByFilterSuccess(legTypes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypesByFilterSuccess = createAction(
  "v3/sales/legType/getLegTypesByFilterSuccess",
  function prepare(legTypes: LegType[]) {
    return {
      payload: {
        legTypes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypeById = createAction(
  "v3/sales/legType/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/legTypes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legType = new LegType(json);
      dispatch(getLegTypeByIdSuccess(legType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypeByIdSuccess = createAction(
  "v3/sales/legType/getLegTypeByIdSuccess",
  function prepare(legType?: LegType) {
    return {
      payload: {
        legType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteLegType = createAction(
  "v3/sales/removeLegTypeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/legTypes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteLegTypeSuccess = createAction(
  "v3/sales/legTypes/deleteLegTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateLegType = createAction(
  "v3/sales/legTypes/updateLegType",
  function prepare(id: number, body: Partial<LegType>) {
    const request = new PutAction(`/v3/sales/legTypes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateLegTypeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateLegTypeSuccess = createAction(
  "v3/sales/legTypes/updateLegTypeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createLegType = createAction(
  "v3/sales/legTypes/createLegType",
  function prepare(body: Partial<LegType>) {
    const request = new PostAction(`/v3/sales/legTypes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const legType = new LegType(json);
      dispatch(createLegTypeSuccess(legType));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createLegTypeSuccess = createAction(
  "v3/sales/legTypes/createLegTypeSuccess",
  function prepare(legType: LegType) {
    return {
      payload: {
        legType,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLegTypesSearchMetadata = createAction(
  "v3/sales/getLegTypesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/legTypes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getLegTypesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLegTypesSearchMetadataSuccess = createAction(
  "v3/sales/getLegTypesSearchMetadataSuccess",
  function prepare(legTypesSearchMetadata: unknown) {
    return {
      payload: {
        legTypesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
