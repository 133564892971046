import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Node } from "../../entities/node";

export const setSelectedNodeID = createAction(
  "setSelectedNodeID",
  function prepare(nodeID?: number, selected?: boolean) {
    return {
      payload: {
        nodeID,
        selected,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodes = createAction(
  "v3/sales/node/getNodes",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodes`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodes = Node.fromList(json);
      dispatch(getNodesSuccess(nodes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodesSuccess = createAction(
  "v3/sales/node/getNodeSuccess",
  function prepare(nodes: Node[]) {
    return {
      payload: {
        nodes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodesByFilter = createAction(
  "v3/sales/node/getNodesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/v3/sales/nodes`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const nodes = Node.fromList(json);
      dispatch(getNodesByFilterSuccess(nodes));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodesByFilterSuccess = createAction(
  "v3/sales/node/getNodesByFilterSuccess",
  function prepare(nodes: Node[]) {
    return {
      payload: {
        nodes,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodeById = createAction(
  "v3/sales/node/getById",
  function prepare(id: number) {
    const request = new GetAction(`/v3/sales/nodes/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const node = new Node(json);
      dispatch(getNodeByIdSuccess(node));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodeByIdSuccess = createAction(
  "v3/sales/node/getNodeByIdSuccess",
  function prepare(node?: Node) {
    return {
      payload: {
        node,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteNode = createAction(
  "v3/sales/removeNodeById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/v3/sales/nodes/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteNodeSuccess = createAction(
  "v3/sales/nodes/deleteNodeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateNode = createAction(
  "v3/sales/nodes/updateNode",
  function prepare(id: number, body: Partial<Node>) {
    const request = new PutAction(`/v3/sales/nodes/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateNodeSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateNodeSuccess = createAction(
  "v3/sales/nodes/updateNodeSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createNode = createAction(
  "v3/sales/nodes/createNode",
  function prepare(body: Partial<Node>) {
    const request = new PostAction(`/v3/sales/nodes`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const node = new Node(json);
      dispatch(createNodeSuccess(node));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createNodeSuccess = createAction(
  "v3/sales/nodes/createNodeSuccess",
  function prepare(node: Node) {
    return {
      payload: {
        node,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getNodesSearchMetadata = createAction(
  "v3/sales/getNodesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/v3/sales/nodes/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getNodesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getNodesSearchMetadataSuccess = createAction(
  "v3/sales/getNodesSearchMetadataSuccess",
  function prepare(nodesSearchMetadata: unknown) {
    return {
      payload: {
        nodesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
