import { createReducer } from "@reduxjs/toolkit";
import { LegType } from "../../entities/legType";
import { arrayToMap } from "../../helpers/reducerHelpers";
import { queryBuilder } from "../../network";
import {
  deleteLegTypeSuccess,
  getLegTypeByIdSuccess,
  getLegTypesSearchMetadataSuccess,
  getLegTypesSuccess,
  setSelectedLegTypeID,
} from "./legTypeActions";
import { LegTypeState } from "./legTypeState";

function initState(): LegTypeState {
  const { legType: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    legTypes: {},
    searchMetadata: undefined,
    selectedLegTypeID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const legTypeReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedLegTypeID, (state, action) => {
    state.selectedLegTypeID = action.payload.legTypeID;
    return state;
  });
  builder.addCase(getLegTypesSuccess, (state, action) => {
    const legTypes = action.payload.legTypes;
    state.legTypes = arrayToMap<LegType>(legTypes);
    return state;
  });
  builder.addCase(getLegTypesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.legTypesSearchMetadata;
    return state;
  });
  builder.addCase(deleteLegTypeSuccess, (state, action) => {
    const legTypeID = action.payload.id;
    if (state.legTypes[legTypeID]) {
      delete state.legTypes[legTypeID];
    }
    return state;
  });
  builder.addCase(getLegTypeByIdSuccess, (state, action) => {
    const legType = action.payload.legType;
    if (legType) {
      const legTypes = { ...state.legTypes };
      legTypes[legType.id] = legType;
      state.legTypes = legTypes;
    }
    return state;
  });
});
